import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { render } from "react-dom";
import "./style.scss";

const meta = {
  title: "Work with us! | Dwellingo",
  description: "Join a dedicated and passionate team over at Dwellingo!",
  url: "careers",
};

const Careers = () => {
  document.getElementById("loadingAnimation").style.display = "none";
  return (
    <div className="careers-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Work with us! | Dwellingo</title>
        <meta name="title" content={meta.title} />
        <meta name="description" content={meta.description} />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.dwellingo.in/${meta.url}`}
        />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta
          property="og:image"
          content="https://www.dwellingo.in/logo512.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content={`https://www.dwellingo.in/${meta.url}`}
        />
        <meta property="twitter:title" content={meta.title} />
        <meta property="twitter:description" content={meta.description} />
        <meta
          property="twitter:image"
          content="https://www.dwellingo.in/logo512.png"
        />
      </Helmet>

      <h1>Come and join our team!</h1>
      <p>
        Do you see yourself as a natural leader? Want to join our growing
        community and meet people from all walks of life? Do you consider
        yourself a people person and a hard worker? Are you ready for a new
        adventure working in the co-living industry in Bangalore? Than we might
        have a dream job for you! We are always on the look out for 10x talent
        if you think you fit the bill then kindly fill the form below and
        someone will get back to you if we think you're a fit.
      </p>
      <section class="form">
        <iframe
          src="https://tally.so/embed/mY6163?alignLeft=1&hideTitle=1&transparentBackground=1"
          width="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          title="Apply to Dwellingo"
        ></iframe>
      </section>
    </div>
  );
};

export default Careers;
